.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: gray;
}

.image {
  height: 50%;
  width: 70%;
}
